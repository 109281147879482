* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'Lato';
  src: url('./fonts/lato-v20-latin-regular.woff') format('woff');
}
@font-face {
  font-family: "方正兰亭细黑";
  src: url('./fonts/fangzhenglantingxihei.ttf') format("truetype");
}

@media print {
  .output-cover {
    display: flex !important;
  }
}

@page {
  size: A4;
  margin: 0
}
