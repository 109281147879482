:root {
  --success-color: rgb(66, 201, 134);
  --main-font-color: rgb(144, 144, 144);
  --main-step-icon-color: rgba(144, 144, 144, .9);
  --secondary-step-icon-color: rgba(144, 144, 144, .5);
}

.InstructionPanel_root {
  width: 100%;
  height: 100%;
  padding: 2em;

  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  text-transform: uppercase;
}

.InstructionPanel_root .close_btn {
  position: absolute;
  right: .5em;
  display: none;
}

.title {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: var(--main-font-color);
}

.title .main {
  font-size: 3rem;
  font-weight: 300;
}

.sub {
  font-size: 1rem;
  font-weight: lighter;
}

.sub_title{
  text-align: center;
  color: var(--main-font-color);
}

.steps {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.steps .step_button {
  padding: .8em;
  border: 1px solid var(--main-font-color);
}

.steps .step_button[disabled] {
  color: var(--secondary-step-icon-color);
  border: 1px solid var(--secondary-step-icon-color);
}

.steps .step_button:hover {
  background-color: white !important
}

.steps .step_button.success {
  color: white;
  border: none;
  background: var(--success-color);
}

.steps .step_button svg{
  font-size: 2.5rem !important;
}

.steps .line {
  width: 2px;
  height: 2em;
  background: var(--secondary-step-icon-color);
}

.steps .line.success{
  width: 2px;
  height: 2em;
  background: var(--success-color);
}

.steps .rounded_button {
  width: 2em;
  height: 4.5em;
  border-radius: 50%;
  border: 2px solid var(--secondary-step-icon-color);
}

.steps .rounded_button.success {
  transition: background-color 2s ease;
  color: var(--success-color);
  border-color: var(--success-color);
}

.arrow polyline {
  stroke: var(--secondary-step-icon-color) !important
}

.arrow.success polyline{
  stroke: var(--success-color) !important
}

.quote button {
  display: block;
  margin-left: auto;
  margin-right: auto;
  background: rgb(247, 155, 84) !important;
  text-transform: uppercase;

  font-size: 1rem;
  font-weight: bold;
  padding: 1em 3em;
  border-radius: 8px;
}

.alert {
  margin-left: auto;
  margin-right: auto;
  font-size: .9rem;
  line-height: 1.5;
  font-weight: lighter;
  text-transform: none;
}

.footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  margin-bottom: 0;
  font-size: .1rem;
  color: var(--main-font-color);
}

.footer a {
  text-decoration: none;
  color: var(--main-font-color);
}

@media screen and (max-width: 834px) {
  .InstructionPanel_root .close_btn {
    display: inline-flex;
  }

  .steps .step_button {
    padding: 0.4em;
  }

  .quote button {
    padding: 0.4em 0.7em;
  }
}
