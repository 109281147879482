.FactsheetTemplate1.print.page {
  width: 420mm;
  height: 592mm;
  overflow: visible;
  margin: 0 auto;
  page-break-after: always;
  color: #000;
  background-color: #FFF;
  margin-bottom: 40mm;
  position: relative;
}

.FactsheetTemplate1.print.page * {
  font-family: 'Lato', "方正兰亭细黑", "Microsoft Yahei","微软雅黑", STXihei, "华文细黑";
}

.FactsheetTemplate1.print table {
  width: 100%;
  background-color: #FFF;
  border-collapse: collapse;
  border-spacing: 0;
  outline: 0;
}

.FactsheetTemplate1.print th, .print td {
  padding: 0;
}

.FactsheetTemplate1.print th {
  text-align: left;
}

.FactsheetTemplate1.print h1, .print h2, .print h3, .print h4, .print h5, .print h6 {
  line-height: 1.1;
}

.FactsheetTemplate1.print .section h1 {
  margin: 0;
  background-color: rgb(254,219,47) !important;
  -webkit-print-color-adjust: exact;
  color: #000;
  font-size: 29px;
  height: 12mm;
  padding: 3mm 4mm;
  font-weight: 600;
  text-shadow: none;
}

.FactsheetTemplate1.print .currency-notice {
  height: 16mm;
  padding: 4mm 0;
  font-size: 16px;
}

.FactsheetTemplate1.print .section-content {
  position: relative;
  font-size: 18px;
}

.FactsheetTemplate1.print .section-content:after {
  content: "";
  display: block;
  clear: both;
}

.FactsheetTemplate1.print .launch-date {
  top: 0;
  right: 0;
  position: absolute;
  height: 16mm;
  padding: 4mm 0;
  font-size: 16px;
  font-weight: 600;
  text-align: right;
}

.FactsheetTemplate1.print .section {
  margin: 0 20mm;
}

.FactsheetTemplate1.print .objective {
  width: 186mm;
}


.FactsheetTemplate1.print .section.intro .objective h1 {
  height: 8mm;
  padding: 1mm 4mm;
  padding-left: 0;
  font-size: 18px;
  background-color: transparent !important;
}

.FactsheetTemplate1.print .section .objective-content {
  height: 50mm;
  line-height: 1.3;
  text-align: justify;
}



.FactsheetTemplate1.print .objective.en {
  float: left;
}

.FactsheetTemplate1.print .objective.cht {
  float: right;
}

.FactsheetTemplate1.print .section.detail .performance h1 {
  font-size: 22px;
  background-color: rgb(255,243,190) !important;
}

.FactsheetTemplate1.print .key-facts {
  width: 186mm;
  height: 92mm;
  float: left;
}

.FactsheetTemplate1.print .top-holdings {
  width: 186mm;
  height: 92mm;
  float: right;
}

.FactsheetTemplate1.print .top-holdings > h1 {
  font-size: 22px;
  background-color: rgb(255,243,190) !important;
  padding-top: 3mm;
}


.FactsheetTemplate1.print .performance {
  float: left;
  width: 100%;
}

.FactsheetTemplate1.print .key-facts table tr {
}

.FactsheetTemplate1.print .top-holdings table tr td:last-child {
  text-align: right;
  padding-right: 4mm;
}


.FactsheetTemplate1.print .key-facts table tr td:nth-child(1) {
  width: 40%;
}

.FactsheetTemplate1.print .performance table tr td:nth-child(1) {
  width: 33%;
  text-align: left;
}

.FactsheetTemplate1.print .performance table tr td {
  text-align: center;
  width: 7%;
  white-space: pre-wrap;
  padding: 2mm 4mm;
  padding-left: 4mm;
}
.FactsheetTemplate1.print .allocations {
}
.FactsheetTemplate1.print .allocations .allocation {
  width: 186mm;
  height: 219mm;
  float: left;
  margin-right: 8mm;
  overflow-x: hidden;
}
.FactsheetTemplate1.print .allocations .allocation:nth-child(2) {
  margin-right: 0;
}
.FactsheetTemplate1.print .allocations .allocation:nth-child(n+3) {
  display: none;
}

.FactsheetTemplate1.print .allocation h1 {
  height: 12mm;
  text-align: center;
  font-size: 22px;
  font-weight: 600;
}

.FactsheetTemplate1.print .allocation table tr:not(:first-child) td:nth-child(1) {
  width: 4%;
  text-align: right;
  padding-right: 12px;
}
.FactsheetTemplate1.print .allocation table tr td:nth-child(4) {
  text-align: right;
}

.FactsheetTemplate1.print .allocation table {
  /* height: 80mm; */
  /* margin-bottom: 50mm; */
  font-size: 18px;
}

.FactsheetTemplate1.print .allocation .pie {
  height: 78mm;
  text-align: center;
  padding: 0mm 0;
}

.FactsheetTemplate1.print .section .allocation h1 {
  font-size: 21px;
  background-color: rgb(255,243,190) !important;
}

.FactsheetTemplate1.print .section.intro h1 {
  background-color: rgb(194,200,219) !important;
  padding: 2mm 4mm;
  white-space: nowrap;
  letter-spacing: -0px;
}
.FactsheetTemplate1.print .section.intro h1 .footnote-symbol {font-size: 20px;vertical-align: top;}

.FactsheetTemplate1.print.page > h1 {
  margin-top: 0;
  margin-bottom: 4mm;
  height: 20mm;
  background-image: url('../aviva-top-bar.png') !important;
  background-size: cover !important;
  padding: 4mm 20mm;
}



.FactsheetTemplate1.print .section.detail > h1 {
  margin-bottom: 4mm;
  padding: 2mm 4mm;
  font-size: 24px;
}

.FactsheetTemplate1.print .section.detail .section-content {
}

.FactsheetTemplate1.print .top-holdings table tr td {
  padding-left: 4mm;
  white-space: nowrap;
}

.FactsheetTemplate1.print .key-facts table tr th {
  font-size: 22px;
  font-weight: 600;
  vertical-align: middle;
}

.FactsheetTemplate1.print .key-facts table tr td {
  font-size: 18px;
  vertical-align: top;
  padding-top: 4mm;
}

.FactsheetTemplate1.print .key-facts table tr:nth-child(1) {
  height: 12mm;
  background-color: rgb(255,243,190) !important;
}

.FactsheetTemplate1.print .key-facts table tr td .value {
  /* white-space: pre-wrap; */
}
.FactsheetTemplate1.print .key-facts table tr td .value .currency-symbol {
  width: 36px;
  display: inline-block;
}

.FactsheetTemplate1.print .top-holdings table {
  font-size: 18px;
  margin-top: 1mm;
}

.FactsheetTemplate1.print .section.detail .performance table {
  font-size: 18px;
  height: 60mm;
}

.FactsheetTemplate1.print .top-holdings table tr {
  line-height: 26px;
}

.FactsheetTemplate1.print .performance table tr {
  line-height: 20px;
}

.FactsheetTemplate1.print .performance table tr:nth-child(1) {
  border-bottom: 1mm solid rgb(254,219,47);
}

.FactsheetTemplate1.print .key-facts table tr td .key {
  font-weight: bold;
  font-style: italic;
}


.FactsheetTemplate1.print .allocation table tr:nth-child(1) td {
  text-align: right;
  padding-bottom: 6px;
}

.FactsheetTemplate1.print .allocation table tr {
  line-height: 24px;
}
.FactsheetTemplate1.print .allocation table tr td:last-child {
  padding-right: 10px;
}

.FactsheetTemplate1.print .footnote {
  /* height: 72mm; */
  font-size: 14px;
  margin: 0 20mm 40px;
  position: absolute;
  bottom: 0;
}
.FactsheetTemplate1.print .footnote--risk-rating {position: relative;bottom: inherit;margin: 3mm 20mm 0;font-size: 14px;}

.FactsheetTemplate1.print .footnote table {
  margin-bottom: 2mm;
  text-align: left;
}

.FactsheetTemplate1.print .footnote table tr td {
  vertical-align: top;
  white-space: pre-line;
}

.FactsheetTemplate1.print .footnote table tr td:nth-child(1) {
  width: 1.3%;
}

.FactsheetTemplate1.print .footnote table tr {
  line-height: 16px;
}

.FactsheetTemplate1.print .key-facts table tr td:nth-child(1) {
  padding-left: 4mm;
}

.FactsheetTemplate1.print .key-facts table tr th:nth-child(1) {
  padding-left: 4mm;
}


.FactsheetTemplate1.preview table {
  box-shadow: inset 0px 0px 0px 2px #000;
  width: 100%;
  background-color: #FFF;
}

.FactsheetTemplate1.print .allocation .pie .chart-container {
  height: 100%;
}

.FactsheetTemplate1.print .allocation .pie .chart-container canvas {
  width: auto !important;
  height: 100% !important;
}


.FactsheetTemplate1.print .allocation .pie .nvd3 text {
  font-size: 8px;
  font-family: serif;
}

.FactsheetTemplate1.print .allocation .nvd3.nv-wrap.nv-pieChart {
/*    transform: translate(40px,60px) scale(1.1);
    transform-origin: center;*/
}

.FactsheetTemplate1.print .allocation .pie .nvd3.nv-pie .nv-pie {
  /* transform: translate(310px,80px) scale(1.1); */
}

.FactsheetTemplate1.print .allocation .pie .nvd3.nv-pie .nv-pieLabels {
  /* transform: translate(310px,84px) scale(0.97); */
}

.FactsheetTemplate1.print .allocation .pie .nvd3.nv-pie path {
  stroke-width: 0px;
  fill-opacity: 1;
}






@media print {
  body{
    -webkit-print-color-adjust:exact;
    /* to fix table background-color */
  }
  .print.page {
    box-shadow: none;
    margin-bottom: 0;
  }
  .particle-edit-outer-wrapper {
    margin-left: 0;
  }
  .particle-editor-container {
    margin-top: 0;
  }
}







.FactsheetTemplate1.risk-rating {
  margin: 0 20mm;
  padding-top: 4mm;
}
.FactsheetTemplate1.risk-rating table {
  line-height: 100%;
}
.FactsheetTemplate1.risk-rating table tr {}
.FactsheetTemplate1.risk-rating table tr > th {
  background-color: rgb(255,243,190) !important;
  height: 46mm;
  border-right: 4px solid #AAA;
  border-bottom: 2px solid #AAA;
  font-weight: lighter;
}
.FactsheetTemplate1.risk-rating table tr > th:first-child {
  padding-left: 4mm;
  width: 60%;
  line-height: 130%;
}
.FactsheetTemplate1.risk-rating table tr > th:last-child {
  border-right: none;
  text-align: center;
  font-size: 22px;
  line-height: 22px;
}
.FactsheetTemplate1.risk-rating table tr > td {
  font-size: 24px;
  border-right: 4px solid #AAA;
  border-bottom: 2px solid #AAA;
  font-weight: lighter;
}
.FactsheetTemplate1.risk-rating table tr > td:last-child {
  border: 0;
}
.FactsheetTemplate1.risk-rating table tr:last-child > td:last-child {
  border-bottom: 2px solid #AAA;
}
.FactsheetTemplate1.risk-rating-scores {line-height: 26px;}
.FactsheetTemplate1.risk-rating-scores--header {
  margin-top: 2mm;
}

.FactsheetTemplate1.risk-rating-scores__score {
  float: left;
  width: 33.3%;
  background-color: rgb(215,216,215) !important;
  box-shadow: inset -2px 0px 0px 0px white;
  text-align: center;
  padding: 3mm 0;
  font-size: 24px;
}
.FactsheetTemplate1.risk-rating-scores__score * {
  color: white !important;
}
.FactsheetTemplate1.risk-rating-scores__score.active {
}
.FactsheetTemplate1.risk-rating-scores__score.active * {
  color: black !important;
}
.FactsheetTemplate1.risk-rating-scores__score.L.active {
  background-color: rgb(252,229,218) !important;
}
.FactsheetTemplate1.risk-rating-scores__score.M.active {
  background-color: rgb(231,126,111) !important;
}
.FactsheetTemplate1.risk-rating-scores__score.H.active {
  background-color: rgb(223,86,82) !important;
}
